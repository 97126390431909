import Config from "@/utils/config.service";

export interface Configuration
{
    apiBaseUrl: string;
    notificationPerHourLimit: number;
}

export default class NotificationsModelConfig {
    public static async getConfiguration(): Promise<Configuration> {
        const configInstance = Config.getInstance();
        const configuration = await configInstance.getConfiguration();

        return configuration.notificationsModule as Configuration;
    }
}