
import { defineComponent } from "vue";

export default defineComponent({
  emits: [
      'set-push-notification'
  ],
  data() {
    return {
      notificationPush: false
    };
  },
  methods: {
    setPushNotification(sendPush: boolean) {
      this.notificationPush = sendPush;
      this.$emit(
          'set-push-notification',
          this.notificationPush
      );
    }
  }
});
