<template lang="html">
  <div class="container">
    <div class="text">
      {{ title }}
    </div>
    <div class="group">
      <div class="notification-type" :class="type.class" v-for="type in types" :key="type" >
        <label :for="type.label" class="label-radio">
          <input type="radio" :id="type.label" :value="type.value" v-model="notificationType" @click="$emit('set-type', type.value)" />
          <span class="checkmark"></span>
        </label>
        <span class="text add">{{ type.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
      'set-type'
  ],
  data: function() {
    return {
      notificationType: [],
      title: 'Typ notifikace *',
      types: [
        {
          class: 'marketing',
          label: 'marketing',
          title: 'Marketingová',
          value: "marketing"
        },
        {
          class: 'transactions',
          label: 'transactions',
          title: 'Transakční',
          value: "transactions"
        },
        {
          class: 'disorder',
          label: 'disorder',
          title: 'Upozornění',
          value: "disorder"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.container {
  margin-top: 40px;
  .text {
    font: normal normal 14px/1.2 Roboto;
    font-stretch: normal;
    letter-spacing: 0.14px;
    text-align: left;
    color: $darkergray;
    margin-bottom: 24px;
    &.add {
      margin-left: .75rem;
    }
  }
  .group {
    display: flex;
    .notification-type {
      &.marketing, &.transactions {
        padding-right: 48px;
      }
      &.disorder {
        padding-right: 98px;
      }
      .label-radio {
        position: relative;
        cursor: pointer;
        input[type=radio] {
          opacity: 0;
          &:checked {
            ~ .checkmark {
              background-color: $red;
              border: 1px solid $red;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark {
          position: absolute;
          top: -2px;
          left: 0;
          height: 18px;
          width: 20px;
          background-color: $white;
          border: 1px solid $gray;
          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 1px;
            width: 3px;
            height: 10px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .text {
        font: normal 500 18px/1.2 Roboto;
        font-stretch: normal;
        text-align: left;
        color: $black;
        margin-bottom: 24px;
        &.add {
          margin-left: .75rem;
        }
      }
    }
  }
}
</style>
