
import { defineComponent } from "vue";
import NotificationsModelConfig from "@/models/Notification/config.model";

export default defineComponent({
  emits: [
      'set-phone-number',
  ],
  data() {
    return {
      notificationConfig: null,
      suggestions: [],
      open: false,
      current: 0,
      cancelTokenSource: this.$http.CancelToken.source(),
      notificationPhone: null,
      selectedMobilePhone: '',
      selectedMobileUser: null,
      searching: false,
      phoneInput: {
          label: 'phone',
          title: 'Telefonní číslo *',
          active: ''
      },
    };
  },
  created: async function() {
    this.notificationConfig = await NotificationsModelConfig.getConfiguration();
  },
  methods: {
    enter() {
      this.selectedMobilePhone = this.suggestions[this.current].phone;
      this.notificationPhone = this.suggestions[this.current].phone;
      this.open = false;
    },
    up() {
      if(this.current > 0)
        this.current--;
    },
    down() {
      if(this.current < this.suggestions.length - 1)
        this.current++;
    },
    isActive(index: number) {
      return index === this.current;
    },
    change() {
      this.current = 0;

      if (this.selectedMobilePhone.length > 6) {
        setTimeout(() => {
          // i am sleeping
        }, 200);

        if (this.searching) {
          this.cancelTokenSource.cancel();
          this.cancelTokenSource = this.$http.CancelToken.source();
        }

        this.searching = true;
        this.$http.get(this.notificationConfig.apiBaseUrl + "notification-center/search-mobile-users?search=" + encodeURIComponent(this.selectedMobilePhone), {
          cancelToken: this.cancelTokenSource.token
        }).then((response) => {
          if (response.status === 200) {
            this.open = true;
            this.searching = false;
            this.suggestions = response.data;
          }
        }).catch((error) => {
          console.log('Error', error);
        });
      }
    },
    suggestionClick(index: number) {
      this.notificationPhone = this.suggestions[index].phone;
      this.selectedMobilePhone = this.suggestions[index].phone;
      this.selectedMobileUser = this.suggestions[index];
      this.suggestions = [];
      this.open = false;

      this.$emit(
          'set-phone-number',
          this.notificationPhone
      );
    },
  },
});
