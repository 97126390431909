
import {defineComponent} from "vue";
import ThePlatform from '@/components/UIComponents/NotificationComponents/ThePlatform.vue';
import TheType from '@/components/UIComponents/NotificationComponents/TheType.vue';
import TheUser from '@/components/UIComponents/NotificationComponents/TheUser.vue';
import ThePush from '@/components/UIComponents/NotificationComponents/ThePush.vue';
import TheCountry from '@/components/UIComponents/NotificationComponents/TheCountry.vue';
import ThePhone from '@/components/UIComponents/NotificationComponents/ThePhone.vue';
import TheNotificationList
  from "@/components/UIComponents/NotificationComponents/NotificationList/TheNotificationList.vue";
// eslint-disable-next-line no-unused-vars
import {Notification} from '@/models/Notification/api.model';
import NotificationsModelConfig from "@/models/Notification/config.model";

export default defineComponent({
  name: 'TheNotificationAll',
  components: {
    TheNotificationList,
    TheType,
    ThePlatform,
    TheUser,
    ThePush,
    TheCountry,
    ThePhone,
  },
  emits: [
    'set-loading',
    'invoke-success',
    'invoke-error',
    'reset-event'
  ],
  mounted() {
    //this.refreshDatePickers();
  },
  renderTriggered() {
    //this.refreshDatePickers();
  },
  created: async function () {
    this.notificationModulConfig = await NotificationsModelConfig.getConfiguration();
    await this.fetchNotifications();
  },
  data() {
    return {
      activeTab: 1,
      notificationModulConfig: null,

      notifications: Array<Notification>(),
      notificationType: 'marketing',
      notificationPush: false,
      notificationTitle: '',
      notificationMessage: '',
      notificationPlatform: Array<string>(),
      notificationCountry: '',
      notificationTargetUserGroup: Array<string>(),
      notificationPhone: '',
      limitSendNotificationPerHour: '0',
      notificationTitleMaxLength: 40,
      notificationMessageMaxLength: 180,

      notificationTitleInput: {
        class: 'first',
        label: 'title',
        title: 'Nadpis notifikace *'
      },
      notificationMessageInput: {
        class: 'second',
        label: 'message',
        title: 'Text notifikace *'
      },
      limitSendNotificationPerHourInput: {
        class: 'second',
        label: 'limitSendNotificationPerHour',
        title: 'Rychlost odesílání notifikace (za hodinu) *'
      }
    };
  },
  methods: {
    startLoading: async function() {
      this.$emit('set-loading', true);
    },
    finishLoading: async function() {
      this.$emit('set-loading', false);
    },
    emitError: async function(message : string) {
      this.$emit("invoke-error", message);  
    },
    emitSuccess: async function(message : string) {
      this.$emit("invoke-error", message);
    },
    prepareNotificationData(): Notification {
      return {
        type: this.notificationType,
        isPushNotification: this.notificationPush,
        platforms: (this.activeTab !== 1) ? null : Array.from(this.notificationPlatform),
        country: (this.activeTab !== 1) ? null : this.notificationCountry,
        targetUserGroup: (this.activeTab !== 1) ? null : Array.from(this.notificationTargetUserGroup),
        phoneNumber: (this.activeTab !== 2) ? null : this.notificationPhone,
        title: this.notificationTitle,
        message: this.notificationMessage,
        limitSendNotificationPerHour: Number.parseInt(this.limitSendNotificationPerHour)
      } as Notification;
    },
    sendNotificationGroup() {
      let isValid = true;

      const notification = this.prepareNotificationData();
      console.log(notification);

      if (notification.type.length === 0) {
        //this.emitError("Nebyl vybrán typ notifikace k odeslání!");
        alert("Nebyl vybrán typ notifikace k odeslání!");
        return false;
      }

      // Hromadne odeslani
      if (this.activeTab === 1) {
        if (notification.country.length === 0) {
          this.emitError("Nebyl vybrán stát, do které poslat notifikaci!");
          //alert("Nebyl vybrán stát, do které poslat notifikaci!");
          return false;
        }
      }

      // Odeslani na konkretniho uzivatele
      if (this.activeTab === 2) {
        if (notification.phoneNumber === '' || notification.phoneNumber === null) {
          alert("Nebylo zadáno žádné číslo pro vyhledání uživatele!");
          return false;
        }
      }

      if (notification.title === '') {
        alert("Nadpis notifikace nebyl pravděpodobně zadán!");
        return false;
      }

      if (notification.title.length < 5) {
        alert('Nadpis notifikace je moc krátký!');
        return false;
      }

      if (notification.message === '' || notification.message.length < 10) {
        alert("Text notifikace nebyl pravděpodobně zadán nebo je text moc krátký - minimum je 10 znaků!");
        return false;
      }

      if (notification.limitSendNotificationPerHour === 0) {
        alert("Rychlost odesílání notifikace nebyla zadána!");
        return false;
      }

      if (notification.limitSendNotificationPerHour > this.notificationModulConfig.notificationPerHourLimit) {
        alert("Maximální limit rychlosti odesílání je " + this.notificationModulConfig.notificationPerHourLimit + "/h!");
        return false;
      }

      // Validace vůči API
      if (this.activeTab === 1) {
        let urlParams = [];

        urlParams.push("country=" + encodeURI(notification.country));

        if (notification.targetUserGroup.length > 0) {
          notification.targetUserGroup.forEach((targetGroup: string) => {
            urlParams.push("targetUserGroups=" + encodeURI(targetGroup));
          });
        }

        if (notification.platforms.length > 0) {
          notification.platforms.forEach((platform: string) => {
            urlParams.push("platforms=" + encodeURI(platform));
          });
        }

        this.$http.get(
            this.notificationModulConfig.apiBaseUrl + "notification-center/count-target-mobile-users?" + urlParams.join("&")
        ).then((response: { status: number; data: { count: number; }; }) => {
          if (response.status === 200) {
            if (response.data.count > 0) {
              this.send(notification);
              isValid = true;
              return;
            }
            alert("Pro zvolené zadání nejsou k dispozici žádní uživatelé!");
            isValid = false;
            return;
          }
          alert("Nepodařilo se zkontolovat zadání a najít uživatele. Zkuste to znovu.");
          isValid = false;
        });
      }

      if (this.activeTab === 2) {
        this.$http.get(
            this.notificationModulConfig.apiBaseUrl + "notification-center/search-mobile-users?search=" + encodeURIComponent(notification.phoneNumber)
        ).then((response: { status: number; data: string | any[]; }) => {
          if (response.status === 200) {
            if (response.data !== '' && Array.isArray(response.data) && response.data.length > 0) {
              this.send(notification);
              isValid = true;
              return;
            }
            alert("Pro zvolené zadání nejsou k dispozici žádní uživatelé!");
            isValid = false;
            return;
          }
          alert("Nepodařilo se zkontolovat zadání a najít uživatele. Zkuste to znovu.");
          isValid = false;
        });
      }

      return isValid;
    },
    send(notification: Notification) {

      this.startLoading();
      
      this.$http.post(this.notificationModulConfig.apiBaseUrl + "notification-center/notification-create", {
        platforms: notification.platforms,
        country: notification.country,
        targetUserGroup: notification.targetUserGroup,
        type: notification.type,
        isPushNotification: notification.isPushNotification,
        phoneNumber: notification.phoneNumber,
        title: notification.title,
        message: notification.message,
        limitSendNotificationPerHour: notification.limitSendNotificationPerHour
      }).then((response: { status: number; }) => {
        if (response.status === 204) {
          this.fetchNotifications();
          alert("Vše proběhlo v pořádku, notifikace byla předána k odeslání.");
          return;
        }
        this.finishLoading();
        alert("Notifikaci se nepodařilo odeslat!");
      });
    },
    fetchNotifications() {
      this.$http.get<Notification[]>(
          this.notificationModulConfig.apiBaseUrl + `notification-center/notification-list`
      ).then((response: { status: number; data: any[]; }) => {
        if (response.status !== 200) {
          this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při načítání dat!");
          return;
        }
        this.notifications = response.data;
        this.finishLoading();
      });
    },
    refreshNotification() {
      this.fetchNotifications();
    },
    setCountry(country: string) {
      this.notificationCountry = country;
    },
    setType(type: string) {
      this.notificationType = type;
    },
    setPushNotification(pushNotification: boolean) {
      this.notificationPush = pushNotification;
    },
    setTargetUserGroups(targetUserGroups: Array<string>) {
      this.notificationTargetUserGroup = targetUserGroups;
    },
    setPlatforms(platforms: Array<string>) {
      this.notificationPlatform = platforms;
    },
    setPhoneNumber(phone: string) {
      this.notificationPhone = phone;
    },
  }
});
