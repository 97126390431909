<template lang="html">
  <div class="select-container">
    <span class="text">Skupina uživatelů</span>
    <div class="group" @click="toggleInput()" v-detect-click="toggleInputOutside">
      <div class="wrap" :class="{ clearEdge : visible }">
        <div class="input" :class="{ edge : visible }">
          <span>{{ groupName }}</span>
        </div>
      </div>
      <div class="arrow" :class="{ dropped : visible }"></div>
      <div :class="{ hidden : !visible, visible }">
        <ul>
          <li
              :class="{ lastLi : (groupList.length-1) === index , current : value.title === groupId }"
              v-for="(value, index) in groupList"
              :key="value"
              @click="select(value.id,value.name);"
          >
            {{ value.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  emits: ['group-id', 'group-name'],
  props: {
    groupId: {
      type: Number
    },
    groupName: {
      type: String
    },
    groupList: {
      type: Array
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    toggleInput() {
      this.visible = !this.visible;
    },
    toggleInputOutside() {
      this.visible = false;
    },
    select(id, name) {
      this.$emit(
          'group-id',
          id
      );
      this.$emit(
          'group-name',
          name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import "@/assets/styles/_container.scss";
</style>
