
import {defineComponent} from "vue";
// import TheNotificationGroupAdminAdd from '@/components/UIComponents/NotificationComponents/NotificationGroup/TheNotificationGroupAdminAdd.vue';
import TheNotificationGroupAdminList from '@/components/UIComponents/NotificationComponents/NotificationGroupAdmin/TheNotificationGroupAdminList.vue';

// eslint-disable-next-line no-unused-vars
import {NotificationGroup, NotificationGroupList} from '@/models/Notification/api.model';
import NotificationsModelConfig from "@/models/Notification/config.model";

export default defineComponent({
  name: 'TheNotificationGroupAdmin',
  components: {
    // TheNotificationGroupAdminAdd,
    TheNotificationGroupAdminList,
  },
  emits: [
    'set-loading',
    'invoke-success',
    'invoke-error',
    'reset-event'
  ],
  mounted() {
    //this.refreshDatePickers();
  },
  renderTriggered() {
    //this.refreshDatePickers();
  },
  created: async function () {
    this.notificationModulConfig = await NotificationsModelConfig.getConfiguration();
    await this.fetchNotificationGroups();
  },
  data() {
    return {
      notificationModulConfig: null,

      notificationGroups: Array<NotificationGroup>(),
      notificationGroupId: null,
      notificationGroupName: '',

      fileContent: '',
      fileExtension: '',
      createdFile: '',
    };
  },
  methods: {
    startLoading: async function () {
      this.$emit('set-loading', true);
    },
    finishLoading: async function () {
      this.$emit('set-loading', false);
    },
    prepareNotificationGroupData(): NotificationGroup {
      return {
        id: this.notificationGroupId,
        name: this.notificationGroupName,
        content : this.fileContent
      } as NotificationGroup;
    },
    resetForm() {
      this.notificationGroupId = null;
      this.notificationGroupName = '';
      this.fileContent = '';
      this.fileExtension = '';
      this.createdFile = '';
    },
    sendNotificationGroup() {
      this.startLoading();

      let isValid = true;

      const notificationGroup = this.prepareNotificationGroupData();
      console.log(notificationGroup);

      if (notificationGroup.name === '') {
        isValid = false;
        this.$emit("invoke-error", "Název skupiny uživatelů notifikace je nutné zadat!");
        return false;
      }

      if (notificationGroup.content === '') {
        isValid = false;
        this.$emit("invoke-error", "Není vybrán soubor k importu!");
        return false;
      }

      if (isValid) {
        const notificationGroupData = this.prepareNotificationGroupData();

        if (this.notificationGroupId === null) {

          this.$http.post(
              this.notificationModulConfig.apiBaseUrl + 'notification-center/notification-group',
              notificationGroupData
          )
              .then((response) => {
                if (response.status !== 200) {
                  this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání: " + response.statusText);
                }
                //console.log(response.data);
                this.notificationGroups.unshift(response.data as NotificationGroup);
                this.resetForm();
                this.finishLoading();
                this.$emit("invoke-success", 'Hotovo!', 'Skupina byla úspěšně uložena.');
              })
              .catch((error) => {
                this.finishLoading();
                this.$emit("invoke-error", "CHYBA při ukládání alertu: " + error);
              });
        } else {

          this.$http.put(
              this.notificationModulConfig.apiBaseUrl + 'notification-center/notification-group/' + this.notificationGroupId,
              notificationGroupData
          )
              .then((response) => {
                if (response.status !== 200) {
                  this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání skupiny: " + response.statusText);
                }
                this.resetForm();
                this.finishLoading();
                this.$emit("invoke-success", 'Hotovo!', 'Alert byl úspěšně uložen.');
              })
              .catch((error) => {
                this.finishLoading();
                this.$emit("invoke-error", "CHYBA při ukládání alertu: " + error);
              });
        }
      }
    },
    fetchNotificationGroups() {
      this.$http.get<NotificationGroupList>(
          this.notificationModulConfig.apiBaseUrl + `notification-center/notification-group-list`
      ).then((response: { status: number; data: NotificationGroupList ; }) => {
        if (response.status !== 200) {
          this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při načítání dat!");
          return;
        }
        console.log(response.data);

        this.notificationGroups = response.data.items;
        this.finishLoading();
      });
    },
    refreshNotificationGroups() {
      this.fetchNotificationGroups();
    },
    selectFile(event: any) {
      const files = event.target.files;
      const file = files[0];

      if (files && file) {
        const reader = new FileReader();
        reader.onload =this.handleFile.bind(this);
        reader.readAsText(file, "utf-8");

        this.fileExtension = 'unknown';
        if (file.type === "application/json") {
          this.fileExtension = "json";
        }
      }
    },
    handleFile(event: any) {
      this.fileContent = event.target.result;
    }
  }
});
