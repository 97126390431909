<template lang="html">
  <div class="container">
    <div class="text">
      {{ title }}
    </div>
    <div class="group">
      <div class="target-user-group" :class="group.class" v-for="group in targetUserGroups" :key="group" >
        <label :for="group.label" class="label-checkbox">
          <input type="checkbox" :id="group.label" :value="group.value" :name="group.label" v-model="notificationTargetUserGroup" @change="setTargetUserGroups" />
          <span class="checkmark"></span>
        </label>
        <span class="text add">{{ group.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
    'set-target-user-groups',
  ],
  data() {
    return {
      notificationTargetUserGroup: [],
      title: 'Typ uživatele',
      targetUserGroups: [
        {
          class: 'sender',
          label: 'sender',
          title: 'Odesílatel',
          value: "UserWithSentPackets"
        },
        {
          class: 'receiver',
          label: 'receiver',
          title: 'Příjemce',
          value: "UserRecipientWithoutSentPackets"
        },
        {
          class: 'new--user',
          label: 'new--user',
          title: 'Nový uživatel',
          value: "NewUser"
        }
      ]
    };
  },
  methods: {
    setTargetUserGroups() {
      this.$emit(
          'set-target-user-groups',
          this.notificationTargetUserGroup
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.container {
  margin-top: 40px;
  .text {
    font: normal normal 14px/1.2 Roboto;
    font-stretch: normal;
    letter-spacing: 0.14px;
    text-align: left;
    color: $darkergray;
    margin-bottom: 24px;
    &.add {
      margin-left: .75rem;
    }
  }
  .group {
    display: flex;
    .target-user-group {
      &.sender, &.receiver {
        padding-right: 48px;
      }
      .label-checkbox {
        position: relative;
        cursor: pointer;
        input[type=checkbox] {
          opacity: 0;
          &:checked {
            ~ .checkmark {
              background-color: $red;
              border: 1px solid $red;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark {
          position: absolute;
          top: -2px;
          left: 0;
          height: 18px;
          width: 20px;
          background-color: $white;
          border: 1px solid $gray;
          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 1px;
            width: 3px;
            height: 10px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .text {
        font: normal 500 18px/1.2 Roboto;
        font-stretch: normal;
        text-align: left;
        color: $black;
        margin-bottom: 24px;
        &.add {
          margin-left: .75rem;
        }
      }
    }
  }
}
</style>
