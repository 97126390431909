
import {defineComponent} from "vue";
import TheNotificationGroupAdminListItem from "@/components/UIComponents/NotificationComponents/NotificationGroupAdmin/TheNotificationGroupAdminListItem.vue";

export default defineComponent({
  name: "TheNotificationGroupAdminList",
  components: {
    TheNotificationGroupAdminListItem
  },
  emits: [
    'refresh-list',
    'start-loading',
    'invoke-success',
    'invoke-error',
  ],
  props: [
    'notificationGroups'
  ],
  methods: {
    refreshNotificationGroups() {
      this.$emit(
          'start-loading'
      );
      this.$emit(
          'refresh-list'
      );
    },
    removeNotificationGroupList() {
      this.$emit(
          'start-loading'
      );
      this.$emit(
          'refresh-list'
      );
    },
  },
});
