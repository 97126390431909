
import { defineComponent } from "vue";
import NotificationsModelConfig from "@/models/Notification/config.model";

export default defineComponent({
  name: "TheNotificationListItem",
  emits: [
      'refresh-notification',
  ],
  props: [
      'notification',
      'activeTab',
  ],
  data() {
    return {
      notificationConfig: null,
    };
  },
  created: async function() {
    this.notificationConfig = await NotificationsModelConfig.getConfiguration();
  },
  methods: {
    approve(notificationId: string) {
      this.$http.put(this.notificationConfig.apiBaseUrl + `notification-center/notification-approved/` + notificationId)
          .then((response) => {
            if (response.status !== 204) {
              alert("Chyba!");
            } else {
              this.$emit(
                  'refresh-notification',
                  notificationId
              );
            }
          });
    },
    cancel(notificationId: string) {
      this.$http.delete(this.notificationConfig.apiBaseUrl + `notification-center/notification-cancel/` + notificationId)
          .then((response) => {
            if (response.status !== 204) {
              alert("Chyba!");
            } else {
              this.$emit(
                  'refresh-notification',
                  notificationId
              );
            }
          });
    },
  },
});
