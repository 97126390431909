
import {defineComponent} from "vue";
import NotificationsModelConfig from "@/models/Notification/config.model";
// eslint-disable-next-line no-unused-vars
import {NotificationGroup} from "@/models/Notification/api.model";

export default defineComponent({
  name: "TheNotificationGroupAdminListItem",
  emits: [
    'refresh-list',
    'invoke-error',
    'invoke-success',
  ],
  props: [
    'notificationGroup',
  ],
  data() {
    return {
      notificationConfig: null,
    };
  },
  created: async function () {
    this.notificationConfig = await NotificationsModelConfig.getConfiguration();
  },
  methods: {
    removeNotificationGroup(index: number, notificationGroup: NotificationGroup) {
      if (!confirm("Opravdu chcete odstranit skupinu?")) {
        return;
      }
      this.$http.delete(this.notificationConfig.apiBaseUrl + 'notification-center/notification-group/' + notificationGroup.id)
          .then((response) => {
            if (response.status === 204) {
              this.$emit(
                  'refresh-list'
              );
              this.$emit(
                  'invoke-success',
                  'Hotovo!',
                  'Skupina byla odstraněna!'
              );
            } else {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    }
  }
});
