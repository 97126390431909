
import { defineComponent } from "vue";
import TheNotificationListItem
  from "@/components/UIComponents/NotificationComponents/NotificationList/TheNotificationListItem.vue";

export default defineComponent({
  name: "TheNotificationList",
  components: {TheNotificationListItem},
  emits: [
      'refresh-notification',
  ],
  props: [
      'notifications',
      'activeTab'
  ],
  methods: {
    refreshNotification(notificationId: string) {
      this.$emit(
          'refresh-notification',
          notificationId
      );
    },
  },
});
