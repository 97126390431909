
import {defineComponent} from "vue";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';
import TheNotificationAll from '@/components/UIComponents/NotificationComponents/TheNotificationAll.vue';
import TheNotificationSingle from '@/components/UIComponents/NotificationComponents/TheNotificationSingle.vue';
import TheNotificationGroup from '@/components/UIComponents/NotificationComponents/TheNotificationGroup.vue';
import TheNotificationGroupAdmin from '@/components/UIComponents/NotificationComponents/TheNotificationGroupAdmin.vue';
import Loader from "@/components/UIComponents/Loader.vue";
import EventResult from "@/components/UIComponents/PromocodeComponents/EventResult.vue";
import ErrorResult from "@/components/UIComponents/NotificationComponents/ErrorResult.vue";
import AuthServiceProxy from "@/utils/auth.service";
import Roles from "@/models/roles.model";

export default defineComponent({
  name: 'TheNotification',
  components: {
    TheNotificationAll,
    TheNotificationSingle,
    TheNotificationGroup,
    TheNotificationGroupAdmin,
    Loader,
    EventResult,
    ErrorResult
  },
  created: async function () {
    if (!AuthServiceProxy.hasRole(Roles.ROLE_NOTIFICATION_SEND)) {
      await this.$router.push({name: 'TheLogin'});
    }
  },
  data() {
    return {
      mainTitle: 'Notifikace',
      tabs: [
        {
          name: 'Všichni uživatelé',
          order: 1
        },
        {
          name: 'Jednotlivec',
          order: 2
        },
        {
          name: 'Skupina',
          order: 3
        },
        {
          name: 'Skupiny - správa',
          order: 4
        }
      ],
      activeTab: 1,
      loading: true,
      error: false,
      errorMsg: '',
      values: ['', ''],
      errorMessage: ['', ''],
      eventTitle: '',
      eventDescription: '',
      validity: null,
      validityTest: 'pending',
      checkMsg: '',
    };
  },
  methods: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    startLoading: async function () {
      this.setLoading(true);
    },
    finishLoading: async function () {
      this.setLoading(false);
    },
    resetEvent() {
      this.finishLoading();
      this.eventTitle = '';
      this.eventDescription = '';
      this.error = false;
      this.errorMsg = '';
    },
    invokeError(message: string) {
      this.finishLoading();
      this.error = true;
      this.errorMsg = message;
      window.scrollTo(0, 0);
    },
    invokeSuccess(title: string, message: string) {
      this.eventTitle = title;
      this.eventDescription = message;
      window.scrollTo(0, 0);
    },
  }
});
