
import { defineComponent } from "vue";

export default defineComponent({
  emits: [
      'set-country',
  ],
  data() {
    return {
      notificationCountry: [],
      title: 'Země *',
      countries: [
        {
          class: 'cz',
          label: 'cz',
          title: 'CZ',
          value: "cz"
        },
        {
          class: 'sk',
          label: 'sk',
          title: 'SK',
          value: "sk"
        },
        {
          class: 'hu',
          label: 'hu',
          title: 'HU',
          value: "hu"
        },
        {
          class: 'ro',
          label: 'ro',
          title: 'RO',
          value: "ro"
        }
      ]
    };
  }
});
